import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon } from '@iconify/react';
import { Box, Card, IconButton, Radio, Stack, Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { TEETH } from '../assets/data/teeth';
import EMPTY from '../assets/illustrations/illustration_empty_content.svg';
import LoadingScreen from '../components/loading-screen';
import { accpetCommand, getCommandById } from '../redux/slices/command/commandThunk';
import { dispatch, useSelector } from '../redux/store';
import ProtheseUserInfo from '../sections/@dashboard/patient/prothese/ProtheseUserInfo';
import { attachementStyle, emptySection } from '../sections/@dashboard/patient/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { PATH_DASHBOARD } from '../routes/paths';
import { useAuthContext } from '../auth/useAuthContext';
import Mouth from '../components/mouth/Mouth';
import { StlViewer } from 'react-stl-viewer';
import { getFileExtension } from '../utils/getFileExtension';
import Tooth from '../assets/icons/Tooth';
import ViewFile from '../components/view-file';
import ChatOrder from '../components/chat-order';
import { getAllComments } from '../redux/slices/comment/commentThunk';
export default function ConsultCommand() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [descriptionState, setDescriptionState] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingAccept, setLoadingAccept] = useState(false);
    const [viewSTL, setViewStl] = useState(false);
    const [currentUrl, setCurrentUrl] = useState('');
    const [isStl, setIsStl] = useState(false);
    useEffect(() => {
        if (id) {
            dispatch(getCommandById({ id }));
            dispatch(getAllComments({ commandId: id }));
        }
    }, [id]);
    const [icons, setIcons] = useState([]);
    useEffect(() => {
        const loadIcons = async () => {
            const loadedIcons = await Promise.all(TEETH.map(async (tooth) => {
                return {
                    id: tooth?.id,
                    icon: await tooth?.icon,
                };
            }));
            setIcons(loadedIcons);
        };
        loadIcons();
    }, []);
    const { enqueueSnackbar } = useSnackbar();
    const data = useSelector((state) => state?.command);
    const description = data?.oneData?.cartePatient?.prothese?.traitement?.description;
    const { comments } = useSelector((state) => state?.comment);
    const confirmed = data?.oneData?.cartePatient?.prothese?.traitement?.dents.map((el) => {
        return Number(el?.name);
    });
    const dents = data?.oneData?.cartePatient?.prothese?.traitement?.dents;
    const attachments = data?.oneData?.cartePatient?.prothese?.attachments;
    const { user } = useAuthContext();
    const phy = data?.oneData?.cartePatient?.prothese?.physicalFootprint;
    const num = data?.oneData?.cartePatient?.prothese?.digitalFootprint;
    return (_jsxs(Stack, { children: [(data?.error || !data?.oneData?.cartePatient) && (_jsxs(Box, { sx: emptySection, children: [_jsx("img", { src: EMPTY, alt: "error", style: { margin: '0 auto' } }), _jsx(Typography, { variant: "body1", color: 'text.secondary', sx: { marginTop: '20px', fontWeight: 'bold' }, children: "Commande introuvable ou quelque chose c'est mal pass\u00E9" })] })), _jsxs(_Fragment, { children: [data?.loading ? (_jsx(LoadingScreen, {})) : (_jsx(_Fragment, { children: data?.oneData && !data?.error && data?.oneData?.cartePatient && (_jsxs(Box, { children: [!data?.oneData?.prothesist ? (_jsx(Card, { sx: {
                                        width: '100%',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '10px',
                                        justifyContent: 'space-between',
                                        padding: '10px 20px',
                                        height: 'fit-content',
                                        marginTop: '15px',
                                        marginBottom: '15px',
                                        alignItems: 'center',
                                    }, children: _jsxs(Box, { sx: {
                                            padding: '15px 30px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '40px',
                                            width: '100%',
                                            justifyContent: 'start',
                                        }, children: [_jsx(Box, { sx: {
                                                    display: 'flex',
                                                    alignItems: 'start',
                                                    gap: '40px',
                                                    justifyContent: 'center',
                                                }, children: _jsxs(Box, { children: [_jsx(Typography, { variant: "h6", color: 'text.secondary', children: data?.oneData?.createdBy?.email }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: data?.oneData?.createdBy?.phone })] }) }), _jsxs(Box, { sx: {
                                                    display: 'flex',
                                                    alignItems: 'start',
                                                    gap: '15px',
                                                    justifyContent: 'center',
                                                }, children: [data?.oneData?.startDate && (_jsx(DesktopDatePicker, { value: new Date(data?.oneData?.startDate), label: 'Date limite', readOnly: true })), data?.oneData?.endDate && (_jsx(DesktopDatePicker, { value: new Date(data?.oneData?.endDate), label: 'Date limite', readOnly: true }))] })] }) })) : (_jsx(_Fragment, { children: data?.oneData?.prothesist?._id === user?._id ? (_jsx(Card, { sx: {
                                            width: '100%',
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: '10px',
                                            justifyContent: 'center',
                                            padding: '20px 40px',
                                            height: 'fit-content',
                                            marginTop: '15px',
                                            marginBottom: '15px',
                                            alignItems: 'center',
                                        }, children: _jsxs(Typography, { color: 'success', variant: "overline", sx: {
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '5px',
                                                color: 'green',
                                            }, children: [_jsx(Icon, { icon: "material-symbols:error", color: "green", width: '20px' }), "Vous avez d\u00E9j\u00E0 accept\u00E9e ou refus\u00E9e cette commande !"] }) })) : (_jsx(Card, { sx: {
                                            width: '100%',
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: '10px',
                                            justifyContent: 'center',
                                            padding: '20px 40px',
                                            height: 'fit-content',
                                            marginTop: '15px',
                                            marginBottom: '15px',
                                            alignItems: 'center',
                                        }, children: _jsxs(Typography, { color: 'error', variant: "overline", sx: {
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '5px',
                                            }, children: [_jsx(Icon, { icon: "material-symbols:error", color: "red", width: '20px' }), "Cette commande est d\u00E9j\u00E0 accept\u00E9e ou refus\u00E9e par un autre utilisateur !"] }) })) })), _jsx(ProtheseUserInfo, { data: data?.oneData?.cartePatient?.prothese?.traitement?.patient }), _jsxs(Box, { display: 'flex', gap: '15px', children: [_jsx(Box, { children: _jsx(Mouth, { confirmed: confirmed }) }), _jsx(Card, { sx: { marginTop: '15px', padding: '15px', width: '60%', flex: '7' }, children: _jsx(Box, { sx: {
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    flexDirection: 'column',
                                                    gap: '20px',
                                                }, children: data?.oneData &&
                                                    dents?.map((el, index) => {
                                                        const selectedTooth = icons.find((tooth) => +tooth.id === +el.name);
                                                        return (_jsxs(Box, { sx: {
                                                                display: 'flex',
                                                                justifyContent: 'start',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                borderRadius: '10px',
                                                            }, children: [_jsxs(Box, { sx: {
                                                                        display: 'flex',
                                                                        gap: '10px',
                                                                        justifyContent: 'center',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'center',
                                                                        borderRadius: '10px',
                                                                        cursor: 'pointer',
                                                                        width: '100px !important',
                                                                    }, children: [_jsx(Box, { sx: {
                                                                                padding: '10px 5px',
                                                                                borderRadius: '20px',
                                                                                width: '100px !important',
                                                                            }, children: _jsx("img", { src: selectedTooth?.icon, alt: "error", style: { margin: '0 auto' } }) }), _jsx(Typography, { variant: "h6", color: 'text.secondary', children: selectedTooth?.id })] }), _jsxs(Stack, { flexDirection: "row", alignItems: "center", gap: "30px", children: [el?.ombre && (_jsxs(Box, { children: [_jsx(Tooth, { color: el?.color }), _jsx(Typography, { variant: "caption", children: el?.ombre })] })), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: el?.type?.code }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: el?.remarks })] })] }, index));
                                                    }) }) }), _jsxs(Card, { sx: { mt: '15px', p: '22px', width: '20%', minWidth: '280px' }, children: [_jsx(Typography, { color: '#6D758F', sx: { fontSize: '18px', fontWeight: 600 }, children: "Pieces jointes :" }), _jsxs(Box, { sx: {
                                                        marginTop: '15px',
                                                    }, children: [_jsxs(Box, { sx: {
                                                                display: 'flex',
                                                                gap: '5px',
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-start',
                                                                borderRadius: 2,
                                                                padding: '10px',
                                                                background: 'white',
                                                                border: `1px solid ${num ? '#1D98BD' : '#F1F3F7'}`,
                                                            }, width: "100%", children: [_jsx(Radio, { checked: num, disabled: true }), _jsx(Typography, { variant: "subtitle1", color: num ? '#006C9C' : '#176C86', children: "Empreinte Num\u00E9rique" })] }), _jsxs(Box, { sx: {
                                                                display: 'flex',
                                                                gap: '5px',
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-start',
                                                                borderRadius: 2,
                                                                padding: '10px',
                                                                background: 'white',
                                                                border: `1px solid ${phy ? '#1D98BD' : '#F1F3F7'}`,
                                                                my: '15px',
                                                            }, width: "100%", children: [_jsx(Radio, { checked: phy, color: "primary", disabled: true }), _jsx(Typography, { variant: "subtitle1", color: phy ? '#006C9C' : '#176C86', children: "Empreinte Physique" })] })] }), _jsx(Box, { sx: attachementStyle, children: attachments?.map((el, key) => {
                                                        const extention = getFileExtension(el?.path);
                                                        return (_jsxs(Card, { sx: {
                                                                width: '100%',
                                                                maxHeight: 300,
                                                                height: 'auto',
                                                                p: 2,
                                                                background: '#F1F7FF',
                                                            }, children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "subtitle2", noWrap: true, children: el?.path?.split('/uploads/')[1] }), _jsx(IconButton, { onClick: (e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                setCurrentUrl(el?.path);
                                                                                setViewStl(true);
                                                                                setIsStl(extention?.toLowerCase() === 'stl' ? true : false);
                                                                            }, size: "small", children: _jsx(Icon, { icon: "mdi:eye-outline" }) })] }), _jsx(Box, { sx: {
                                                                        width: '100%',
                                                                        my: 1,
                                                                        height: 150,
                                                                        overflow: 'hidden',
                                                                        cursor: 'grab',
                                                                    }, children: extention?.toLowerCase() === 'stl' ? (_jsx(StlViewer, { orbitControls: true, url: el?.path, modelProps: { scale: 1.4 } })) : (_jsx(Box, { component: "img", src: el?.path, sx: {
                                                                            borderRadius: 2,
                                                                            objectFit: 'contain',
                                                                            height: '100%',
                                                                            margin: '0 auto',
                                                                            mixBlendMode: 'multiply',
                                                                        } })) }), _jsx("a", { download: true, onClick: (e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        saveAs(el?.path);
                                                                    }, children: _jsx(IconButton, { size: "small", children: _jsx(Icon, { icon: "material-symbols:download", color: "black", width: '20px' }) }) })] }, key));
                                                    }) })] })] }), description && (_jsx(Card, { sx: {
                                        marginTop: '15px',
                                        padding: '15px',
                                        display: 'flex',
                                        aligntItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                    }, children: _jsxs(Typography, { color: 'warning', variant: "subtitle1", sx: {
                                            display: 'flex',
                                            alignItems: 'start',
                                            justifyContent: 'start',
                                            gap: '5px',
                                        }, children: [_jsx(Icon, { icon: "material-symbols:error", color: "orange", width: 20, height: 20 }), description] }) })), data?.oneData && !data?.error && data?.oneData?.cartePatient && (_jsxs(Card, { sx: { marginTop: '15px', padding: '15px' }, children: [_jsx(ChatOrder, { setDescriptionState: setDescriptionState, descriptionState: descriptionState, commandId: id ?? '' }), _jsxs(Box, { sx: { display: 'flex', flexDirection: 'row-reverse', gap: '10px', my: 2 }, children: [_jsx(LoadingButton, { loading: loadingAccept, variant: "contained", sx: {
                                                        justifySelf: 'flex-end',
                                                        height: 'fit-content',
                                                    }, color: "success", onClick: () => {
                                                        setLoadingAccept(true);
                                                        const params = descriptionState
                                                            ? {
                                                                description: descriptionState,
                                                                status: 'received',
                                                            }
                                                            : { status: 'received' };
                                                        if (id) {
                                                            dispatch(accpetCommand({
                                                                id: id,
                                                                params,
                                                            })).then((res) => {
                                                                setLoadingAccept(false);
                                                                if (res?.payload?.statusCode === 200) {
                                                                    enqueueSnackbar('la commande est acceptée avec succès');
                                                                    navigate(PATH_DASHBOARD.orders);
                                                                }
                                                                else {
                                                                    enqueueSnackbar(res.payload?.message || "Quelque chose c'est mal passé !", {
                                                                        variant: 'error',
                                                                    });
                                                                }
                                                            });
                                                        }
                                                    }, children: "Accepter" }), _jsx(LoadingButton, { loading: loading, variant: "contained", sx: {
                                                        justifySelf: 'flex-end',
                                                        height: 'fit-content',
                                                    }, color: "error", onClick: () => {
                                                        setLoading(true);
                                                        const params = descriptionState
                                                            ? {
                                                                description: descriptionState,
                                                                status: 'rejected',
                                                            }
                                                            : { status: 'rejected' };
                                                        if (id) {
                                                            dispatch(accpetCommand({
                                                                id: id,
                                                                params,
                                                            })).then((res) => {
                                                                setLoading(false);
                                                                if (res?.payload?.statusCode === 200) {
                                                                    enqueueSnackbar('La commande est rejetée avec succès.');
                                                                    navigate(PATH_DASHBOARD.orders);
                                                                }
                                                                else {
                                                                    enqueueSnackbar(res.payload?.message || "Quelque chose c'est mal passé !", {
                                                                        variant: 'error',
                                                                    });
                                                                }
                                                            });
                                                        }
                                                    }, children: "Refuser" })] })] }))] })) })), _jsx(ViewFile, { viewSTL: viewSTL, onClose: () => {
                            setViewStl(false);
                            setCurrentUrl('');
                        }, currentUrl: currentUrl, isStl: isStl })] })] }));
}
